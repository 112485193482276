import { Component } from "@angular/core";
import { RadioButton, RadioButtonsModule } from "@storeblocks/radio-buttons-ng";
import { combineLatest, map, Observable } from "rxjs";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { FmsService } from "src/app/services/fms.service";
import { FormModule } from "@storeblocks/form-ng";
import { AsyncPipe } from "@angular/common";
import { FmsPipe } from "../../../pipes/fms.pipe";

@Component({
  selector: "app-afp-form-field",
  templateUrl: "./afp-form-field.component.html",
  styleUrls: ["./afp-form-field.component.scss"],
  standalone: true,
  imports: [FormModule, RadioButtonsModule, AsyncPipe, FmsPipe],
})
export class AfpFormFieldComponent {
  public radioButtons$: Observable<Array<RadioButton>>;

  constructor(
    private readonly profileService: ProfileService,
    private readonly fms: FmsService,
  ) {
    this.radioButtons$ = combineLatest([
      this.profileService.hasAfp$,
      this.fms.translateAsync<string>("afpFormField.yes"),
      this.fms.translateAsync<string>("afpFormField.no"),
    ]).pipe(map(([hasAfp, yesText, noText]) => createRadioButtons(hasAfp, yesText, noText)));
  }

  public updateAfp(item: RadioButton): void {
    const booleanValue = stringToBoolean(item.value);
    this.profileService.setProfileHasAfp(booleanValue);
  }
}

function createRadioButtons(hasAfp: boolean, yesText: string, noText: string): Array<RadioButton> {
  return [
    {
      label: yesText,
      value: true.toString(),
      disabled: false,
      checked: hasAfp,
    },
    {
      label: noText,
      value: false.toString(),
      disabled: false,
      checked: !hasAfp,
    },
  ];
}

function stringToBoolean(booleanString: "true" | "false"): boolean {
  return booleanString === "true";
}
